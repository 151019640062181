import { toast } from 'react-toastify';

export const checkURLforErrors = (router) => {
  const { query, pathname } = router;
  const { toast_error } = router.query;

  if (toast_error) {
    const errorList = {
      1: 'Your saved design limit is reached. Please delete old designs, if you want to save new designs',
      2: 'Error Creating New Project. Please Try Again',
      3: 'The product you are trying to view is currently unavailable.',
      4: 'This product does not exist.',
      5: 'This page is not available at this time.',
      6: 'No token found in URL',
    };
    toast.error(errorList[toast_error]);
    // Remove the 'yourQueryParam' from the query
    delete query.toast_error;

    // Use the replace method to update the URL without a page refresh
    router.replace({
      pathname,
      query,
    });
  }
};
